import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../Hooks/Auth/useAuth';
import Config from '../../config.json';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme: Theme) => ({
    homePage: {
        padding: theme.spacing(4),
        height: '100%',
        gap: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
    },
    paperContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        color: '#FFFFFF',
        gap: theme.spacing(5),
        '& > *': {
            width: theme.spacing(35),
            height: theme.spacing(12),
        },
    },
    paper: {
        cursor: 'pointer',
        border: '0.5px solid #B0B0B0',
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.action.hover,
    },
    pageTitle: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        flexDirection: 'column',
        alignContent: 'center',
        height: '100%',
        textDecoration: 'none',
        '& > *': {
            alignSelf: 'center',
        },
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));

type TitledSectionProps = {
    title: string;
};

const TitledSection: React.FC<TitledSectionProps> = ({ title, children }) => {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <Typography className={classes.paperContainer} variant="h6" color="textPrimary">
                {title}
            </Typography>
            <div className={classes.paperContainer}>{children}</div>
        </div>
    );
};

export const HomePage: React.FC = () => {
    const classes = useStyles();
    const { currentUser } = useAuth().state;

    const collabUrl = Config.VSF_K8S_W3DS_URL;
    const toCollabUrl = (msSystem?: string) => {
        return `${collabUrl}${msSystem}?auth=${currentUser.jwt_token}`;
    };

    return (
        <>
            <div className={classes.homePage}>
                <TitledSection title="Management">
                    <div className={classes.paper}>
                        <Link
                            to="/users"
                            className={classes.pageTitle}
                            id="homepage-management-users"
                        >
                            <Typography variant="h6" color="textPrimary">
                                Users
                            </Typography>
                        </Link>
                    </div>
                    <div className={classes.paper}>
                        <Link
                            to="/room-management"
                            className={classes.pageTitle}
                            id="homepage-management-reality-linking"
                        >
                            <Typography variant="h6" color="textPrimary">
                                Reality Linking
                            </Typography>
                        </Link>
                    </div>
                    <div className={classes.paper}>
                        <Link
                            to="/event-management"
                            className={classes.pageTitle}
                            id="homepage-management-domains"
                        >
                            <Typography variant="h6" color="textPrimary">
                                Domains
                            </Typography>
                        </Link>
                    </div>
                    <div className={classes.paper}>
                        <Link
                            to="/use-cases-management"
                            className={classes.pageTitle}
                            id="homepage-management-characters"
                        >
                            <Typography variant="h6" color="textPrimary">
                                Characters
                            </Typography>
                        </Link>
                    </div>
                </TitledSection>
                {currentUser.roles.some(
                    (role: any) => role.role === 'admin' || role.role === 'event-admin',
                ) && (
                    <TitledSection title="Quick Actions">
                        <div className={classes.paper}>
                            <Link
                                to={{ pathname: toCollabUrl('/space-ms') }}
                                target="_blank"
                                className={classes.pageTitle}
                                id="homepage-quick-actions-create-world"
                            >
                                <Typography variant="h6" color="textPrimary">
                                    Create World
                                </Typography>
                            </Link>
                        </div>
                        <div className={classes.paper}>
                            <Link
                                to="/create-use-case"
                                className={classes.pageTitle}
                                id="homepage-quick-actions-create-characters"
                            >
                                <Typography variant="h6" color="textPrimary">
                                    Create Characters
                                </Typography>
                            </Link>
                        </div>
                        <div className={classes.paper}>
                            <Link
                                to="/report-user-management"
                                className={classes.pageTitle}
                                id="homepage-quick-actions-user-report"
                            >
                                <Typography variant="h6" color="textPrimary">
                                    User Report System
                                </Typography>
                            </Link>
                        </div>
                        <div className={classes.paper}>
                            <Link
                                to={{ pathname: toCollabUrl('/catalogue-ms') }}
                                target="_blank"
                                className={classes.pageTitle}
                                id="homepage-quick-actions-catalogue"
                            >
                                <Typography variant="h6" color="textPrimary">
                                    Catalogue
                                </Typography>
                            </Link>
                        </div>
                    </TitledSection>
                )}
            </div>
        </>
    );
};
