import React, { FC } from 'react';
import Container from '@material-ui/core/Container';
import ReportedUserManagementTableContainer from '../../Containers/ReportedUserManagementTableContainer/ReportedUserManagementTableContainer';
import { ReportedUserManagementContextProvider } from '../../Contexts/reportedUserManagementContext';
import { Breadcrumbs, Link } from '@mui/material';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    breadcrumb: {
        marginTop: theme.spacing(6),
    },
}));

export const ReportedUserManagementPage: FC = () => {
    const classes = useStyles();
    return (
        <ReportedUserManagementContextProvider>
            <Container maxWidth={'xl'}>
                <div className={classes.breadcrumb}>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                        <Link color="inherit" href="/" id="report-user-management-breadcrumb-home">
                            Home
                        </Link>
                        <Link
                            color="textPrimary"
                            href="/users"
                            aria-current="page"
                            id="report-user-management-breadcrumb-report-user-management"
                        >
                            Report User Management
                        </Link>
                    </Breadcrumbs>
                </div>
                <ReportedUserManagementTableContainer />
            </Container>
        </ReportedUserManagementContextProvider>
    );
};
