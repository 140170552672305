import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { EventAdministrationItems } from '../../Enums/EventAdministrationItems.enum';
import Typography from '@material-ui/core/Typography';
import CancelAndConfirmButton from '../../Components/CancelAndConfirmButton/CancelAndConfirmButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getProvidersList, putProviderLink } from '../../Services/http/auth.service';
import { Preference } from '../../Models/event.model';
import { getPreferencesByCategory, postPreference } from '../../Services/http/event.service';
import { useSnackbar } from 'notistack';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import PreferenceRowDropdown from '../../Components/PreferenceRow/PreferenceRowDropdown';
import { AuthContext } from '../../Contexts/authContext';
import { Role } from '../../Models/user.model';
interface PreferencePair {
    preference: Preference;
    preferenceControl: Preference;
}

const useStyles = makeStyles(() => ({
    providerContainer: {
        margin: '0 20px 10px 20px',
    },
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
        marginTop: '20px',
        width: '100%',
    },
    titleRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    name: {
        width: '50%',
    },
    infoBar: {
        width: '15%',
    },
    textBox: {},
    default: {
        fontWeight: 'bold',
        width: '15%',
    },
    paragraph: {
        display: 'flex',
        flexDirection: 'row',
    },
    dropdown: {
        width: '10%',
        margin: '0 auto',
    },
    checkbox: {
        display: 'flex',
        width: '15%',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    title: {
        display: 'flex',
        width: '15%',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
}));

const IdentityProviderAccordionContainer = () => {
    const classes = useStyles();
    const { state } = useEventManagement();

    const [providersPreference, setProviderPreference] = useState<PreferencePair[]>([]);
    const currentUser = useContext(AuthContext).state.currentUser;
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const { enqueueSnackbar } = useSnackbar();

    const getProvidersPreference = useCallback(async () => {
        //user_pass_login
        const user_pass_Body = {
            event_code: state.eventCode,
            name: 'user_pass_login',
        };
        const userPassLoginPreference = await getPreferencesByCategory(user_pass_Body).then(
            (response: any) => {
                return response.data[0];
            },
        );

        const body = {
            event_code: state.eventCode,
        };
        const authPreferences = await getProvidersList(body).then((response: any) => {
            const providers = response.data.providers;
            const initialPreferences = providers.map((provider: any) => {
                provider.value = JSON.parse(JSON.stringify(provider.signup_type));
                provider.initialValue = JSON.parse(JSON.stringify(provider.signup_type));
                return provider;
            });
            userPassLoginPreference.initialValue = JSON.parse(
                JSON.stringify(userPassLoginPreference.value),
            );
            initialPreferences.push(userPassLoginPreference);
            return initialPreferences;
        });

        const controlBody = {
            event_code: state.eventCode,
            type: 'specific',
            category: 'provider_control',
        };
        const providerControlPreferences = await getPreferencesByCategory(controlBody).then(
            (response: any) => {
                const preferences = response.data;
                const initialPreferences = preferences.map((preference: any) => {
                    preference.initialValue = JSON.parse(JSON.stringify(preference.value));
                    return preference;
                });
                return initialPreferences;
            },
        );
        const finalPreferences = authPreferences.map((preference: any) => {
            const matchingControlPreference = providerControlPreferences.find(
                (p: Preference) => p.name.split('_control')[0] === preference.name,
            );
            if (matchingControlPreference) {
                return { preference, preferenceControl: matchingControlPreference };
            } else {
                const preferenceControl = {
                    name: `${preference.name}_control`,
                    event_code: state.eventCode,
                    initialValue: 'no',
                    value: 'no',
                    category: 'provider_control',
                };
                return { preference, preferenceControl };
            }
        });
        setProviderPreference(finalPreferences);
    }, [state.eventCode]);

    useEffect(() => {
        setIsAdmin(currentUser.roles.some((role: Role) => role.role === 'platform-admin'));
    }, [currentUser]);

    useEffect(() => {
        const hasChange = providersPreference.some(({ preference, preferenceControl }) => {
            if (preference?.value && preference.initialValue !== preference.value) {
                return true;
            }
            if (
                preferenceControl?.value &&
                preferenceControl.initialValue !== preferenceControl.value
            ) {
                return true;
            }
            return false;
        });
        if (hasChange) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [providersPreference]);

    const handleChange = (newValue: any, key: any, type: keyof PreferencePair) => {
        const providers = providersPreference.map((provider) => {
            if (provider[type].name === providersPreference[key][type].name) {
                provider[type].value = newValue;
            }
            return provider;
        });
        setProviderPreference(providers);
    };

    const onCancel = () => {
        setDisabled(true);
        getProvidersPreference();
    };

    const putProviderPreference = async (providerPreference: Preference) => {
        const body = {
            provider: providerPreference.name,
            signup_type: providerPreference.value,
            event_code: state.eventCode,
        };
        return await putProviderLink(body)
            .then((response: any) => {
                enqueueSnackbar(`${response.data.message} for ${providerPreference.name}`, {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            })
            .catch((e) => {
                enqueueSnackbar(e.data.message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            });
    };

    const postProviderPreference = async (provider: Preference) => {
        const body = {
            name: provider.name,
            value: provider.value,
            category: provider.category,
            event_code: state.eventCode,
            type: 'string',
        };
        return await postPreference(body)
            .then((response: any) => {
                enqueueSnackbar(`${response.data.message} for ${provider.name}`, {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            })
            .catch((e) => {
                enqueueSnackbar(e.data.message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                });
            });
    };

    const onSubmit = async () => {
        await Promise.all(
            providersPreference.map(async ({ preference, preferenceControl }) => {
                if (preference.name === 'user_pass_login') {
                    if (preference.value && preference.initialValue !== preference.value) {
                        await postProviderPreference(preference);
                    }
                } else {
                    if (preference.signup_type && preference.initialValue !== preference.value) {
                        await putProviderPreference(preference);
                    }
                }

                if (
                    preferenceControl.value &&
                    preferenceControl.initialValue !== preferenceControl.value
                ) {
                    await postProviderPreference(preferenceControl);
                }
            }),
        );
        await getProvidersPreference();
    };

    useEffect(() => {
        getProvidersPreference();
    }, [getProvidersPreference]);

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="provider-content"
                id="provider-header"
            >
                <Typography>
                    <strong>{EventAdministrationItems.IDENTITY_PROVIDER}</strong>
                </Typography>
            </AccordionSummary>
            <div className={classes.providerContainer}>
                <div className={classes.textBox}>
                    <div className={classes.paragraph}>
                        <Typography className={classes.default}>
                            <strong>no</strong>
                        </Typography>
                        <Typography>Login option is not available</Typography>
                    </div>
                    <div className={classes.paragraph}>
                        <Typography className={classes.default}>
                            <strong>yes</strong>
                        </Typography>
                        <Typography>
                            Login option is available, but role must be granted manually
                        </Typography>
                    </div>
                    <div className={classes.paragraph}>
                        <Typography className={classes.default}>
                            <strong>auto</strong>
                        </Typography>
                        <Typography>
                            Login option is available and visitor role is granted automatically
                        </Typography>
                    </div>
                    <div className={classes.paragraph}>
                        <Typography className={classes.default}>
                            <strong>access_code</strong>
                        </Typography>
                        <Typography>
                            Login option is available. Visitor role is granted automatically only if
                            a correct access code is provided
                        </Typography>
                    </div>
                </div>
                <div className={classes.root}>
                    {isAdmin && (
                        <div className={classes.titleRow}>
                            <div className={classes.title}></div>
                            <div className={classes.dropdown}></div>
                            <div className={classes.checkbox}>
                                <Typography className={classes.default}>
                                    <strong>Show for domain-admins</strong>
                                </Typography>
                            </div>
                            <div className={classes.infoBar}></div>
                        </div>
                    )}
                    {providersPreference[0] &&
                        providersPreference?.map((provider, key) => {
                            if (
                                isAdmin ||
                                (!isAdmin && provider.preferenceControl.value === 'yes')
                            ) {
                                return (
                                    <PreferenceRowDropdown
                                        isAdmin={isAdmin}
                                        key={key}
                                        preference={provider.preference}
                                        preferenceControl={provider.preferenceControl}
                                        originalOnChange={(e: any) =>
                                            handleChange(e, key, 'preference')
                                        }
                                        originalOnChangeCheckbox={(e: any) =>
                                            handleChange(e, key, 'preferenceControl')
                                        }
                                    />
                                );
                            }
                        })}
                </div>
            </div>
            <CancelAndConfirmButton onCancel={onCancel} onSubmit={onSubmit} disabled={disabled} />
        </Accordion>
    );
};

export default IdentityProviderAccordionContainer;
